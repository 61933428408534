<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
import { isAuthenticated } from "@/core/services/jwt.service";
export default {
  beforeCreate() {
    if (isAuthenticated()) {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>
